<template>
  <div class="shopList">
    <div id="map" style="width: 0px; height: 0px"></div>
    <p class="topLine" @click.prevent="hideMap">
      <i></i>
    </p>
    <p class="shopPageTitle" v-if="businessType == 2 || businessType == 3">
      {{ new_XZCT_fenDianWeiZhi }}
    </p>
    <p class="shopPageTitle" v-else>{{ new_XZCT_xuanZeDiZhi }}</p>
    <div class="seachBox" @touchstart="startHandle" @touchend="endHandle">
      <van-icon name="search" size="20" />
      <input
        @input="searchPlace"
        v-model.trim="searchWord"
        type="text"
        :placeholder="shuRuName"
        id="address"
      />
      <span></span>
    </div>
    <div
      v-if="businessType == 2 || businessType == 3"
      class="shopTab"
      @touchstart="startHandle"
      @touchend="endHandle"
    >
      <p @click="changeTab(1)" :class="{ active: tabActive == 1 }">
        {{ nearbyShop }}
      </p>
      <p @click="changeTab(2)" :class="{ active: tabActive == 2 }">
        {{ recentShop }}
      </p>
    </div>
    <div
      class="shops"
      ref="shops"
      :style="boxHeight"
      :class="{ active: shopActive }"
      @touchstart="startHandle"
      @touchend="endHandle"
    >
      <div v-if="businessType == 2 || businessType == 3">
        <shop-item
          @click="selectShop(item)"
          v-for="item in shopList"
          :key="item.shopCode"
          :shopInfo="item"
          :tabActive="tabActive"
          :showDetail="showDetail"
        ></shop-item>
      </div>
      <div class="placeBox" v-else>
        <!-- <p class="nearPlace">{{ nearby }}</p> -->
        <div class="shopTab active">
          <p @click="changeTabDelivery(1)" :class="{ active: tabActive == 1 }">
            {{ nearbyShop }}
          </p>
          <p @click="changeTabDelivery(2)" :class="{ active: tabActive == 2 }">
            {{ new_XZCT_woDe }}
          </p>
        </div>
        <div v-if="tabActive == 1">
          <div
            class="placeItem"
            v-for="item in placeList"
            :key="item.place_id"
            @click="
              selectPlace(item.coordinate, item.name, item.formatted_address)
            "
          >
            <p>{{ item.name }}</p>
            <div>
              <p>{{ item.formatted_address }}</p>
              <p class="selectBtn">{{ new_XZCT_xuanZe }}</p>
            </div>
          </div>
        </div>
        <div v-if="tabActive == 2">
          <div
            class="placeItem"
            v-for="(item, index) in placeListMine"
            :key="index"
            @click="selectMyPlace(index)"
          >
            <p>{{ item.district }}</p>
            <div>
              <p>{{ item.building }}</p>
              <p class="selectBtn">{{ new_XZCT_xuanZe }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-box
      @finish="closeBox"
      v-show="showBox"
      :title="title"
      :content="content"
      :show="showBtn"
    ></confirm-box>
  </div>
</template>
<script>
import ShopItem from "./ShopItem.vue";
import ConfirmBox from "../common/CnfirmBox.vue";
import language from "../../assets/js/language";
import { gcj02towgs84 } from "../../assets/utils/transfrom";
import { wgs_gcj_encrypts } from "../../assets/utils/coordinate";

import {
  postDC,
  postPaas,
  // postPlace,
  // getPlace,
} from "../../assets/utils/request";
export default {
  name: "ShopList",
  data() {
    return {
      showDetail: true,
      tabActive: 1,
      shopActive: false,
      startClient: 0,
      endClient: 0,
      listType: 1,
      content: "",
      title: "",
      showBtn: false,
      showBox: false,
      searchWord: "",
      shopList: [],
      placeList: [],
      placeListMine: [], //wode地址
      localinfo: null,
      pageType: null,
      couponInfo: null, //首页带过来的优惠券信息
      bannerInfo: null, //首页banner带过来的信息
      timer: null, //节流定时器
      page: null,
      geocoder: null,
      map: null,
      shopInfo: null,
      add: false, //是否時添加地址
      lanType: this.$store.state.language,
      leaveCopyInfo: null,
      boxHeight: "",
      // 静态文字
      nearby: "",
      shuRuName: "",
      nearbyShop: "",
      recentShop: "",
      new_XZCT_xuanZe: "",
      new_XZCT_fenDianWeiZhi: "",
      new_XZCT_xuanZeDiZhi: "",
      new_XZCT_woDe: "",
      HQXXSB: "",
      BZPSFWN: "",
      HQPSFYHSB: "",
      nearNoShop: "",
    };
  },
  props: {
    businessType: {
      type: String,
      default: "0",
    },
    appInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    changeTabDelivery(type) {
      if (type == 1) {
        this.searchPlaceWord();
      } else {
        if (!this.$store.state.userInfo) {
          var alertText = "請先登記或登入";
          if (this.lanType != 1) {
            alertText = "Please register or login";
          }
          return this.$toast(alertText);
        } else {
          this.getMyPlace();
        }
      }
      this.tabActive = type;
      this.placeList = [];
    },
    async getMyPlace() {
      var params = {
        actionName: "candao.member.addressGet",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      if (this.$store.state.userInfo) {
        let placeListMine = await postPaas("UserUnify", params);
        if (placeListMine.status == 1 && placeListMine.data.data) {
          this.placeListMine = placeListMine.data.data;
        }
      }
    },
    // 選擇我的地址
    selectMyPlace(index) {
      // 没有地址从中转页 Transfer 跳过来
      if (this.$route.query.transfer) {
        // 从当前页面跳到餐单页面 第一种方案
        // let query = this.$route.query;
        // let transferP = JSON.stringify(query);
        // transferP = encodeURIComponent(query);
        // this.$router.push("/orderFood/" + transferP);
        // 返回上一页 自动跳转餐单页面
        // 设置 KFCJoinType 为1 返回上一页自动跳转餐单页面
        localStorage.setItem("KFCJoinType", 1);
      }
      this.$store.commit("savePlace", this.placeListMine[index]);
      this.$router.back(-1);
    },
    // 搜索地址
    searchPlace() {
      if (this.tabActive == 2) return;
      clearInterval(this.timer);
      this.timer = setTimeout(() => {
        this.searchPlaceWord();
      }, 500);
    },
    async searchPlaceWord() {
      if (!this.searchWord) return;
      // 餐厅
      if (this.businessType == 2 || this.businessType == 3) {
        if (this.tabActive == 1) {
          this.getNearShop();
        }
        return;
      }
      var language = "en";
      if (this.$store.state.language == 1) {
        language = "zh-HK";
      }
      // var str = encodeURIComponent(this.searchWord);
      if (this.businessType == 1 || !this.businessType) {
        //搜索附近多个地址
        var searchMap = new window.google.maps.Map(
          document.getElementById("address"),
          {}
        );
        var places = new window.google.maps.places.AutocompleteService();
        places.getQueryPredictions(
          {
            input: this.searchWord,
            language: language,
          },
          (result, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              this.placeList = result;
              const placesService = new window.google.maps.places.PlacesService(
                searchMap
              );
              this.placeList = [];
              for (let i = 0; i < result.length; i++) {
                this.getDetailPlace(placesService, result[i].place_id);
              }
            }
          }
        );
        return;
      }
    },
    getDetailPlace(place, place_id) {
      place.getDetails(
        {
          placeId: place_id,
          fields: [
            "name",
            "formatted_address",
            "geometry",
            "address_component",
          ],
        },
        (result, status) => {
          if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            var inHK = false;
            if (result.address_components) {
              for (var i = 0; i < result.address_components.length; i++) {
                if (
                  result.address_components[i].short_name.indexOf("HK") > -1 ||
                  result.address_components[i].short_name.indexOf("hk") > -1 ||
                  result.address_components[i].short_name.indexOf("香港") > -1
                ) {
                  inHK = true;
                  break;
                }
              }
            }
            if (inHK) {
              var LAT = result.geometry.location.lat();
              var LNG = result.geometry.location.lng();
              result.coordinate = {
                lat: LAT,
                lng: LNG,
              };
              this.placeList.push(result);
            }
            //成功
          }
        }
      );
    },
    distance(lat, lng, goalLat, goalLng) {
      //传入位置纬度，经度和目标纬度，经度，返回距离值，单位米，对地理感兴趣的童鞋可以去研究下计算公式
      var EARTH_RADIUS = 6378.137; //地球赤道半径
      if (lat != "" && lng != "" && goalLat != "" && goalLng != "") {
        var radLat1 = this.rad(goalLat);
        var radLat2 = this.rad(lat);
        var a = radLat1 - radLat2;
        var b = this.rad(goalLng) - this.rad(lng);
        var s =
          2 *
          Math.asin(
            Math.sqrt(
              Math.pow(Math.sin(a / 2), 2) +
                Math.cos(radLat1) *
                  Math.cos(radLat2) *
                  Math.pow(Math.sin(b / 2), 2)
            )
          );
        s = s * EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000;
        return s * 1000;
      } else {
        return 0;
      }
    },
    rad(d) {
      return (d * Math.PI) / 180.0;
    },
    // 选择地址 {lat: 22.368611, lng: 114.113056}
    async selectPlace(info, name, address) {
      var locationInfo = info;
      if (this.shopInfo) {
        // 获取门店白名单地址
        let whiteListAddressParam = {
          actionName: "candao.storeOwn.listWhiteListAddress",
          content: {
            address: address,
          },
        };
        let whiteListAddress = await postDC("Action", whiteListAddressParam);
        // 如果不再白名单内要查看是否在配送范围内
        if (
          whiteListAddress.status == 1 &&
          whiteListAddress.data &&
          whiteListAddress.data.length == 0
        ) {
          // 查看是否在配送范围内
          var coordinateRes = wgs_gcj_encrypts(
            locationInfo.lat,
            locationInfo.lng
          );

          let deliveryRangeParam = {
            actionName: "candao.storeStandard.getStoreDeliveryRange",
            content: {
              storeId: this.shopInfo.storeId,
              coordinate: [coordinateRes.lng, coordinateRes.lat],
            },
          };
          var deliveryRange = await postDC("Action", deliveryRangeParam);
        }
        // // 白名单调用成功 继续下一步

        if (whiteListAddress.status == 1) {
          if (!deliveryRange && deliveryRange.status != 1) {
            return this.$toast(this.HQPSFYHSB);
          }
          if (!whiteListAddress.data.length && !deliveryRange.data.length) {
            this.$toast(this.BZPSFWN);
            return;
          }
        } else {
          this.$toast(this.HQXXSB);
          return;
        }
      }
      var copyInfo = {
        building: name,
        district: address,
        lat: locationInfo.lat,
        lng: locationInfo.lng,
      };
      if (this.add) {
        this.$store.commit("saveAddPlaceInfo", copyInfo);
        this.$router.back(-1);
        return;
      }
      this.leaveCopyInfo = copyInfo;
      this.$store.commit("saveLocation", locationInfo);
      this.$store.commit("savePlace", copyInfo);
      this.$store.commit("saveLocationName", address);
      // 没有地址从中转页 Transfer 跳过来
      if (this.$route.query.transfer) {
        // 从当前页面跳到餐单页面 第一种方案
        // let query = this.$route.query;
        // let transferP = JSON.stringify(query);
        // transferP = encodeURIComponent(query);
        // this.$router.push("/orderFood/" + transferP);
        // 返回上一页 自动跳转餐单页面
        // 设置 KFCJoinType 为1 返回上一页自动跳转餐单页面
        localStorage.setItem("KFCJoinType", 1);
        this.$router.back();
        return;
      }

      if (this.pageType == 1 || this.page == 1) {
        if (
          this.appInfo &&
          JSON.stringify(this.appInfo != "{}") &&
          this.appInfo.app
        ) {
          var param = this.appInfo;
          param.positionInfo = {
            cityName: "Hong Kong",
          };
          param.positionInfo.address = copyInfo.district;
          param.positionInfo.latitude = copyInfo.lat;
          param.positionInfo.longitude = copyInfo.lng;
          param = JSON.stringify(param);
          param = encodeURIComponent(param);
          this.$router.push("/orderFood/" + param);
        } else {
          this.$router.back(-1);
        }
      } else {
        this.$router.push("/orderFood/1");
      }
    },
    changeNearShop(center) {
      if (this.tabActive == 1) {
        this.getNearShop(center);
      }
    },
    // 获取附近门店
    async getNearShop(center) {
      if (this.businessType != 2 && this.businessType != 3) return;
      var coordinate = [];
      if (center) {
        coordinate = [center.lng, center.lat];
      } else if (this.$store.state.placeInfo) {
        coordinate = [
          this.$store.state.placeInfo.lng,
          this.$store.state.placeInfo.lat,
        ];
      } else if (this.localinfo) {
        coordinate = [this.localinfo.lng, this.localinfo.lat];
      }
      // if (!coordinate.length && !coordinate[0]) {
      //   return;
      // }
      var params = {
        actionName: "candao.storeStandard.getStoreList",
        langType: 1,
        content: {
          cityId: 810000, // 香港id   澳门 id  820000
          // cityId: "11626",
          businessType: [this.businessType],
          searchName: this.searchWord,
          pageNow: 1,
          pageSize: 100,
        },
      };
      if (coordinate.length && coordinate[0] && coordinate[1]) {
        params.content.coordinate = coordinate;
      }
      if (this.$store.state.language != 1) {
        params.langType = 2;
      }
      if (this.$store.state.area != 0) {
        params.content.cityId = 820000;
        // params.content.cityId = "11625";
      }
      let result = await postDC("Action", params);
      if (result.status == 1) {
        if (result.data.rows.length) {
          for (var t = 0; t < result.data.rows.length; t++) {
            result.data.rows[t].businessStatus = result.data.rows[
              t
            ].businessStatus.splice(0, 3);
            result.data.rows[t].businessStatus.forEach((val, index) => {
              if (val.businessId == this.businessType) {
                result.data.rows[t].busy = val.busy;
              }
              if (val.businessId == 1) {
                result.data.rows[t].businessStatus[index].businessName =
                  this.$store.state.language == 1 ? "外送速遞" : "Delivery";
              } else if (val.businessId == 2) {
                result.data.rows[t].businessStatus[index].businessName =
                  this.$store.state.language == 1 ? "自取" : "Self Pick-up";
              } else if (val.businessId == 3 || val.businessId == 4) {
                result.data.rows[t].businessStatus[index].businessName =
                  this.$store.state.language == 1 ? "堂食" : "Dine-in";
              }
            });
          }
          this.shopList = result.data.rows;
          let listOffest = this.$refs.shops.getBoundingClientRect();
          this.boxHeight = `height:calc(100vh - ${listOffest.top + 20}px)`;

          var lat;
          var lng;
          lat = coordinate[1];
          lng = coordinate[0];
          for (var i = 0; i < result.data.rows.length; i++) {
            var point = gcj02towgs84(
              Number(result.data.rows[i].coordinate[0]),
              Number(result.data.rows[i].coordinate[1])
            );
            result.data.rows[i].coordinate[1] = point[1];
            result.data.rows[i].coordinate[0] = point[0];
            var distance = this.distance(
              result.data.rows[i].coordinate[1],
              result.data.rows[i].coordinate[0],
              lat,
              lng
            );
            result.data.rows[i].distance = parseInt(distance);
          }
          this.$nextTick(() => {
            this.$emit("shopList", result.data.rows);
          });
        } else {
          this.$toast(this.nearNoShop);
        }
      } else {
        this.$toast(this.nearNoShop);
      }
    },
    // 获取常用门店
    async getCyShop() {
      //
      var params = {
        actionName: "candao.member.favoriteShopGet",
        content: {},
      };
      let result = await postPaas("UserUnify", params);

      if (result.status == 1) {
        this.shopList = result.data.data;
        for (var i = 0; i < this.shopList.length; i++) {
          var arr = [this.shopList[i].lng, this.shopList[i].lat];
          this.shopList[i].coordinate = arr;
          var lat;
          var lng;
          if (this.localinfo) {
            lat = this.localinfo[1];
            lng = this.localinfo[0];
          } else if (this.$store.state.placeInfo) {
            lat = this.$store.state.placeInfo.lat;
            lng = this.$store.state.placeInfo.lng;
          }
          var distance = this.distance(
            this.shopList[i].lat,
            this.shopList[i].lng,
            lat,
            lng
          );
          this.shopList[i].distance = parseInt(distance);
        }

        this.$emit("shopList", this.shopList);
        if (!this.shopList.length) {
          this.$toast(this.nearNoShop);
        }
      } else {
        this.$toast(result.msg);
      }
    },
    changeTab(type) {
      if (type == 1) {
        this.getNearShop();
      } else {
        if (!this.$store.state.userInfo) {
          var alertText = "請先登記或登入";
          if (this.lanType != 1) {
            alertText = "Please register or login";
          }
          return this.$toast(alertText);
        }
        this.getCyShop();
      }
      this.tabActive = type;
      this.$emit("shopList", []);
      this.shopList = [];
    },
    hideMap() {
      // this.shopActive = !this.shopActive;
      // this.$emit("hideMap");
    },
    startHandle(e) {
      if (this.shopActive) {
        this.startClient = e.touches[0].clientY;
      }
    },
    endHandle() {
      // if (this.shopActive) {
      //   if (
      //     e.changedTouches[0].clientY - this.startClient > 50 &&
      //     e.changedTouches[0].pageY < 200
      //   ) {
      //     this.hideMap();
      //   }
      // }
    },
    closeBox() {
      this.showBox = !this.showBox;
    },
    dataLayerPush(info) {
      window.dataLayer.push({
        event: "store_selector",
        ecommerce: {
          Store_address: info.storeAddress,
          Store_area: info.cityName,
          Store_longlat: info.coordinate.join(","),
          Allow_GPS: true,
          User_longlat: this.$store.state.user_longlat
            ? this.$store.state.user_longlat.join(",")
            : "",
        },
      });
      let ecommerce = {
        Service: this.businessType == 2 ? "delivery" : "dine in",
        user_area: "HK",
        Allow_GPS: true,
        Store: info.storeName,
        Store_num: info.storeId,
        // fulfillment_time,
        user_longlat: this.$store.state.user_longlat
          ? this.$store.state.user_longlat.join(",")
          : "",
      };
      window.dataLayer.push({
        event: "localise",
        ecommerce,
      });
    },
    // 选择餐厅
    selectShop(info) {
      this.dataLayerPush(info);
      // 判断是否在营业时间内 先不要删 或许甲方回改回来
      // var week = new Date().getDay();
      // if (week == 0) {
      //   week = 7;
      // }
      // console.log(info)
      // if (info.businessTimePros && info.businessTimePros.length) {
      //   if (info.businessTimePros[0].days.indexOf(week) < 0) {
      //     this.showBox = !this.showBox;
      //     return;
      //   }
      // }
      // if (info.businessTimes && info.businessTimes.length) {
      //   var nowTime = new Date().getTime();
      //   var year = new Date().getFullYear();
      //   var month = new Date().getMonth() + 1;
      //   var day = new Date().getDate();
      //   var date = year + "-" + month + "-" + day;
      //   var startTime = new Date(
      //     date + " " + info.businessTimes[0].beginTime
      //   ).getTime();
      //   var endTime = new Date(
      //     date + " " + info.businessTimes[0].endTime
      //   ).getTime();
      //   if (nowTime < startTime || nowTime > endTime) {
      //     this.showBox = !this.showBox;
      //     return;
      //   }
      // }
      if (info.busy) return;
      info.H5 = true;
      info.businessType = this.businessType || 3;
      info.appSelfPick = this.$store.state.app;
      if (this.couponInfo) {
        info.coupon = this.couponInfo;
      }
      if (this.bannerInfo) {
        for (var key in this.bannerInfo) {
          info[key] = this.bannerInfo[key];
        }
      }
      info = JSON.stringify(info);
      info = encodeURIComponent(info);
      this.$router.push("/orderFood/" + info);
    },
    // 点击地图图标进入餐厅
    clickMapShop(index) {
      this.selectShop(this.shopList[index]);
    },
    // 初始化地图
    initialize() {
      this.geocoder = new window.google.maps.Geocoder();
      var latlng = new window.google.maps.LatLng(-34.397, 150.644);
      var mapOptions = {
        zoom: 15,
        center: latlng,
      };
      this.map = new window.google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
    },
    setLanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.nearby = language.nearby[type];
      this.shuRuName = language.shuRuName[type];
      this.nearbyShop = language.nearbyShop[type];
      this.recentShop = language.recentShop[type];
      this.new_XZCT_xuanZe = language.new_XZCT_xuanZe[type];
      this.new_XZCT_fenDianWeiZhi = language.new_XZCT_fenDianWeiZhi[type];
      this.new_XZCT_xuanZeDiZhi = language.new_XZCT_xuanZeDiZhi[type];
      this.new_XZCT_woDe = language.new_XZCT_woDe[type];
      this.HQXXSB = language.HQXXSB[type];
      this.BZPSFWN = language.BZPSFWN[type];
      this.HQPSFYHSB = language.HQPSFYHSB[type];
      this.content = language.DQMDBZYYSJN[type];
      this.title = language.QXZQTMD[type];
      this.nearNoShop = language.nearNoShop[type];
    },
  },
  mounted() {
    this.setLanguage();
    if (this.businessType != 1) {
      this.getNearShop();
    }
    var param = this.$route.query;
    this.pageType = param.type;
    this.page = param.page;
    if (param.add) {
      this.add = param.add;
    }
    if (param.coupon) {
      this.couponInfo = JSON.parse(decodeURIComponent(param.coupon));
    }
    if (param.shopInfo) {
      this.shopInfo = JSON.parse(param.shopInfo);
    }
    if (param.bannerInfo) {
      this.bannerInfo = JSON.parse(param.bannerInfo);
    }
    if (param.pageName == "mine") {
      this.showDetail = false;
    }
  },
  components: {
    ShopItem,
    ConfirmBox,
  },
};
</script>
<style scoped>
.shopList {
  background-color: #fff;
  width: 100%;
  border-radius: 0.08rem 0.08rem 0 0;
  overflow: hidden;
}
.topLine {
  width: 100%;
  height: 0.28rem;
  background: #fafbfc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topLine i {
  width: 0.32rem;
  height: 0.04rem;
  background: #e3e4e6;
  border-radius: 0.06rem;
}
.seachBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.36rem;
  margin: 0.16rem;
  border: 1px solid #cacbcc;
  border-radius: 0.08rem;
  padding: 0 0.12rem;
}
.seachBox input {
  width: 2.4rem;
  height: 100%;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  padding: 0 0.12rem;
  outline: none;
  border: none;
  color: #030f14;
}
.seachBox input::placeholder {
  color: #b1b1b3;
}
.seachBox span {
  width: 0.2rem;
  height: 0.2rem;
  background: url("../../assets/icon/locaPlace.png") no-repeat center;
  background-size: cover;
}
.shopTab {
  width: 100%;
  height: 0.3rem;
  margin-top: 0.16rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #474747;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.shopTab.active {
  margin: 0;
  padding: 0;
}
.shopTab p {
  margin-right: 0.24rem;
  border-bottom: 2px solid #fff;
  line-height: 0.3rem;
  font-weight: 600;
}
.shopTab p.active {
  border-color: #e4022b;
  color: #e4022b;
}
.shops {
  height: 3.1rem;
  margin-top: 0.08rem;
  overflow-y: scroll;
}
.shops.active {
  height: 80vh !important;
}
.placeBox {
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.nearPlace {
  height: 22px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 22px;
  color: #474747;
  text-align: left;
}
.placeItem {
  height: 0.79rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0.16rem;
  border-bottom: 0.01rem solid #e3e4e6;
}
.placeItem p:first-child {
  width: 2.4rem;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #030f14;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.placeItem div p:first-child {
  width: 2.4rem;
  /* height: 0.17rem; */
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.17rem;
  color: #cacbcc;
  margin-top: 0.08rem;
  text-align: justify;
}
.placeItem div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectBtn {
  width: 0.58rem;
  height: 0.29rem;
  border-radius: 0.15rem;
  font-size: 0.14rem;
  color: #fff;
  background-color: #e4022b;
  line-height: 0.3rem;
}
.shopPageTitle {
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  text-align: left;
  margin-top: 0.16rem;
  padding-left: 0.16rem;
}
</style>