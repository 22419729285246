<template>
  <div>
    <topbar
      :bgColor="bgColor"
      :title="title"
      :rightType="shoploca"
      category="store_selector"
    ></topbar>
    <div id="map" :class="{ active: hide }"></div>
    <div class="shopListBox" :class="{ active: hide }">
      <shop-list
        ref="shopList"
        :businessType="businessType"
        @shopList="getShopList"
        @hideMap="hideMap"
        :appInfo="appInfo"
      ></shop-list>
    </div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import ShopList from "../../components/shop/ShopList.vue";
// import { GoogleMap, Marker } from "vue3-google-map";
import language from "../../assets/js/language";

export default {
  name: "SelectShop",
  data() {
    return {
      bgColor: "rgba(255,255,255,.4)",
      title: "",
      shoploca: 0,
      hide: false,
      center: { lat: 22.302404, lng: 114.194524 },
      markerIcon: require("../../assets/icon/mapLogo.png"),
      markerOptions: {
        url: require("../../assets/icon/mapLogo.png"),
        size: { width: 40, height: 40, f: "px", b: "px" },
        scaledSize: { width: 40, height: 40, f: "px", b: "px" },
      },
      map: null,
      markers: [],
      appInfo: null,
      businessType: 0,
      shopListInfo: [],
      lanType: this.$store.state.language,
      timer: null,
      // 静态文字
      choosere: "",
    };
  },
  methods: {
    changeCenter() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        var center = this.$refs.map.center;
        this.center = center;
        this.$refs.shopList.changeNearShop(center);
      }, 500);
    },
    getShopList(params) {
      for (var i = 0; i < params.length; i++) {
        params[i].position = {
          lat: params[i].coordinate[1],
          lng: params[i].coordinate[0],
        };
      }
      if (params && params.length) {
        this.center = params[0].position;
      }
      this.shopListInfo = params;
      this.setMaker();
    },
    hideMap() {
      this.hide = !this.hide;
      if (this.hide) {
        if (this.businessType == 1) {
          this.title = "選擇地址";
        } else {
          this.title = "選擇餐廳";
        }
        this.shoploca = 1;
        this.bgColor = "#fff";
      } else {
        this.title = "";
        this.shoploca = 0;
        this.bgColor = "rgba(255,255,255,.4)";
      }
    },
    selectPlace(index) {
      this.$refs.shopList.clickMapShop(index);
    },
    // 设置语言
    setLanguage() {
      var type = "chinese";
      if (this.lanType == 1) {
        type = "english";
      }
      this.choosere = language.choosere[type];
    },
    setMaker() {
      for (var a = 0; a < this.markers.length; a++) {
        this.markers[a].visible = false;
      }
      this.markers = [];
      for (var i = 0; i < this.shopListInfo.length; i++) {
        var marker = new window.google.maps.Marker({
          position: this.shopListInfo[i].position,
          map: this.map,
          icon: {
            url: require("../../assets/icon/mapLogo.png"),
            size: { width: 40, height: 40, f: "px", b: "px" },
            scaledSize: { width: 40, height: 40, f: "px", b: "px" },
          },
        });
        if (this.shopListInfo.length) {
          this.$nextTick(() => {
            this.map.setCenter(this.shopListInfo[0].position);
          });
        }
        this.markers.push(marker);
      }
    },
    initMap() {
      var url =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAsvvcMhcg_aVl8llTs-xJwwhVujT9yKLo&libraries=places&v=weekly&language=en&callback=initMap";
      if (this.$store.state.language == 1) {
        url =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyAsvvcMhcg_aVl8llTs-xJwwhVujT9yKLo&libraries=places&v=weekly&language=zh-HK&callback=initMap";
      }
      var script = document.createElement("script");
      script.src = url;
      script.setAttribute("async", true);
      document.head.appendChild(script);
      window.initMap = () => {
        var center;
        if (this.$store.state.placeInfo) {
          center = {
            lat: this.$store.state.placeInfo.lat,
            lng: this.$store.state.placeInfo.lng,
          };
        }
        this.map = new window.google.maps.Map(document.getElementById("map"), {
          center: center || this.center,
          zoom: 15,
        });
      };
    },
  },

  created() {
    this.$nextTick(() => {
      this.initMap();
    });
    var param = this.$route.query;
    if (param.type) {
      this.businessType = param.type;
    }
    if (param.appInfo) {
      this.appInfo = JSON.parse(param.appInfo);
    }
  },
  components: {
    Topbar,
    ShopList,
  },
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 30vh;
  margin-top: -0.44rem;
}
#map.active {
  height: 0;
}
#map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shopListBox {
  height: 70vh;
  overflow: hidden;
}
.shopListBox.active {
  height: auto !important;
  margin-top: 0.2rem;
}
</style>